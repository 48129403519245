<template>
  <div class="login-container minSize">
    <Header></Header>
    <div class="finish">
      <div class="content">
        <my-icon
          class="succesIcon"
          :fontSize="48"
          :target="'succesIcon'"
          :iconName="'iconwangluojiance_wancheng_48_lv'"
        ></my-icon>
        <div class="title">{{content || $t('login.resetPwd')}}</div>
        <div class="jump">
         <span v-if="$i18n.locale === 'zh-CN'">页面 {{count}} 秒后跳转</span>
         <span v-else>page Resend in {{count}} s </span>
         <span class="jumpBtn" @click="onJump">{{$t('login.goHands')}}</span>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Vue from "vue";

import Header from "@/components/header/Header";
import Footer from "@/components/footer/Footer";
import Back from "@/components/back/Back";

Vue.component("Header", Header);
Vue.component("Footer", Footer);
Vue.component("Back", Back);

export default {
  props: {
    content: { default: "" },
  },
  data() {
    return {
      timer: null,
      count: 10,
    };
  },
  mounted() {
    this.goto();
  },
  computed: {},
  destroyed() {
    clearInterval(this.timer);
  },
  methods: {
    // 返回倒计时
    goto() {
      let that = this;
      if (!that.timer) {
        that.timer = setInterval(() => {
          if (that.count > 1 && that.count <= 10) {
            that.count = that.count - 1;
          } else {
            that.$router.push("/login");
            clearInterval(that.timer);
          }
        }, 1000);
      }
    },
    // 跳转
    onJump() {
      let that = this;
      that.$router.push("/login");
    },
  },
};
</script>

<style lang="scss" scoped>
.login-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.finish .content {
    height: 200px;
    position: absolute;
    left: calc(50% - 120px);
    top: calc(50% - 100px);
  .succesIcon {
    width: 48px;
    margin: 0 auto;
    margin-top: 20px;
  }
  .title {
      width:300px;
      height: 30px;
      font-size: 24px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 30px;
      margin: 0 auto;
      margin-top: 30px;
      text-align: center;
    }
    .jump {
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
      margin-top: 30px;
      .jumpBtn {
        color: #1ab370;
        cursor: pointer;
      }
    }
}
</style>
